import ImgC1 from '../assets/img/tecnologia/azure.png'
import ImgC2 from '../assets/img/tecnologia/sqlserver.svg'
import ImgC3 from '../assets/img/tecnologia/netcore.png'
import ImgC4 from '../assets/img/tecnologia/react.png'
import ImgC5 from '../assets/img/tecnologia/mysql.png'
import ImgC6 from '../assets/img/tecnologia/vue.png'
import ImgC7 from '../assets/img/tecnologia/aws.png'
import ImgC8 from '../assets/img/tecnologia/postgresql.png'
import ImgC9 from '../assets/img/tecnologia/nodejs.png'
import ImgC10 from '../assets/img/tecnologia/Python-Logo.png'
import ImgC11 from '../assets/img/tecnologia/swift_logo.png'
import ImgC12 from '../assets/img/tecnologia/kotlin.png'
import ImgC13 from '../assets/img/tecnologia/react-native.png'
import ImgC14 from '../assets/img/tecnologia/next-js-logo.png'
import ImgC15 from '../assets/img/tecnologia/logo_flutter.png'
import ImgC16 from '../assets/img/tecnologia/mongodb.png'

export default function Carousel(){
    return(
        <>
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active"> 
                        <div className="container">  
                            <div className="row">
                                <div className="col-lg-3 col-6 align-self-center">
                                    <img src={ImgC1} className="d-block w-100 img-slider" alt="img" />
                                </div>
                                <div className="col-lg-3 col-6 align-self-center">
                                    <img src={ImgC2} className="d-block w-100 img-slider" alt="img" />
                                </div>
                                <div className="col-lg-3 col-6 align-self-center">
                                    <img src={ImgC3} className="d-block w-100 img-slider" alt="img" />
                                </div>
                                <div className="col-lg-3 col-6 align-self-center">
                                    <img src={ImgC4} className="d-block w-100 img-slider" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-6 align-self-center">
                                    <img src={ImgC5} className="d-block w-100 img-slider" alt="img" />
                                </div>
                                <div className="col-lg-3 col-6 align-self-center">
                                    <img src={ImgC6} className="d-block w-100 img-slider" alt="img" />
                                </div>
                                <div className="col-lg-3 col-6 align-self-center">
                                    <img src={ImgC7} className="d-block w-100 img-slider" alt="img" />
                                </div>
                                <div className="col-lg-3 col-6 align-self-center">
                                    <img src={ImgC8} className="d-block w-100 img-slider" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-6 align-self-center">
                                    <img src={ImgC9} className="d-block w-100 img-slider" alt="img" />
                                </div>
                                <div className="col-lg-3 col-6 align-self-center">
                                    <img src={ImgC10} className="d-block w-100 img-slider" alt="img" />
                                </div>
                                <div className="col-lg-3 col-6 align-self-center">
                                    <img src={ImgC11} className="d-block w-100 img-slider" alt="img" />
                                </div>
                                <div className="col-lg-3 col-6 align-self-center">
                                    <img src={ImgC12} className="d-block w-100 img-slider" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-6 align-self-center">
                                    <img src={ImgC13} className="d-block w-100 img-slider" alt="img" />
                                </div>
                                <div className="col-lg-3 col-6 align-self-center">
                                    <img src={ImgC14} className="d-block w-100 img-slider" alt="img" />
                                </div>
                                <div className="col-lg-3 col-6 align-self-center">
                                    <img src={ImgC15} className="d-block w-100 img-slider" alt="img" />
                                </div>
                                <div className="col-lg-3 col-6 align-self-center">
                                    <img src={ImgC16} className="d-block w-100 img-slider" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </>
    )
}

// interface carouselProps{
//      litaItems: [];
// }