import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
export default function Nosotros(){
    return(
    <>
    <main className="mt-5 pt-5 bg-scalar-base text-light">
            <div className="container marketing pb-5">
              
                <h2 className="title-scalar">Contacto</h2>
                <div className="row justify-content-md-center">
                    <p className="col-lg-6 d-grid justify-content-md-center">
                    <FontAwesomeIcon className="w-100 fs-1 pt-3 pb-3" icon={faEnvelope} />
                        <a className="text-white-50" href="mailto:gian.gomez@scalar.pe">gian.gomez@scalar.pe</a>
                        <a className="text-white-50" href="mailto:angel.torre@scalar.pe">angel.torre@scalar.pe</a>
                        <hr></hr>
                        <FontAwesomeIcon className="w-100 fs-1 pt-3 pb-3" icon={faPhone} />
                        <a className="text-white-50" href="tel:51930431035">930 431 035</a>
                        <a className="text-white-50" href="tel:51933390084">933 390 084</a>
                         
                    </p>
                </div>  
            </div>
    </main>
       
    </>
    )

}