import { useEffect } from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';
import Menu from './utils/Menu';
import Footer from './utils/Footer';

// import rutas from './route-config';
import Home from './Home';
import Nosotros from './Nosotros';
import Portafolio from './Portafolio';
import Contacto from './Contacto';
function App() {

    useEffect(() => {
    });

  return (
    <div className="App">
      <BrowserRouter>
      <Menu></Menu>
      <Routes>        
        {/* {rutas.map(
          ruta => <Route key={ruta.path} path={ruta.path} element={ruta.component}  />)} */}
          
          <Route path="/nosotros" element={<Nosotros />} /> 
          <Route path="/inicio" element={<Home />} />
          <Route path="/portafolio" element={<Portafolio />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/" element={<Home />}/>    
          <Route path="*" element={<Home />}/>
      </Routes>
      <Footer></Footer>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
