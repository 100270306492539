import Carousel from "./utils/Carousel";
import Coding from './assets/img/Coding_Flatline.png';

export default function Nosotros(){
    return(
    <>
    <main className="mt-5 pt-5 bg-scalar-base text-light">
            <div className="container marketing">
                <img src={Coding} alt="img" />
                <h2 className="title-scalar">Nosotros</h2>
                <div className="row justify-content-md-center">
                    <p className="col-lg-6">
                    Scalar es un equipo de profesionales altamente capacitados con experiencia en el desarrollo de Aplicaciones Web y Móviles. Nos destacamos por nuestro compromiso integral con la excelencia en cada proyecto que emprendemos.
                    <br /><br /><b>¿Qué hacemos?</b> <br />
                    Nos especializamos en el desarrollo de plataformas que optimizan y potencian su negocio o proyecto. Nuestro enfoque se centra en analizar detalladamente sus procesos empresariales con el objetivo de automatizar aquellas áreas que requieren eficiencia y agilidad. De esta manera, proporcionamos soluciones que le permiten administrar su negocio de manera fluida, eliminando complicaciones y demoras. Nos enorgullece ofrecer soluciones intuitivas y de fácil uso, asegurando una experiencia sencilla para nuestros clientes. Con Scalar, su visión se convierte en realidad a través de soluciones tecnológicas innovadoras y adaptadas a sus necesidades específicas.
                    </p>
                </div>
                <hr className="featurette-divider" />
                <h4 className="pt-4">Tecnología con las que trabajamos</h4>
                <Carousel></Carousel>
            </div>
    </main>
       
    </>
    )

}