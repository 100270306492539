import ImgBanner1 from '../assets/img/banner-img-1.png';
import ImgBanner2 from '../assets/img/banner-img-2.png';
import ImgBanner3 from '../assets/img/banner-img-3.png';
import './Header.css';
export default function Header(){
    return(
        <>
        <div className="circles">
                <svg className="circle circle1" viewBox="0 0 440 440" xmlns="http://www.w3.org/2000/svg">
                    <path fill='#212529' d="M220,402.91711652206016C275.6966709187822,392.21778286543446,279.8885443209809,320.06543144032895,308.15432122026846,270.8959210867508C336.2457470283242,222.0297018054833,397.847060724667,181.6527292947955,377.3897730217345,129.1309721782075C354.22253708631786,69.65173150212208,283.8267398410238,43.76464545038278,220,44.56973771665891C157.58955535686232,45.35696523982859,102.81482594291913,80.33698558937537,69.340365423309,133.01661942113668C32.86974925670314,190.41138174000935,10.786862902258704,262.27560796031605,43.93164477462849,321.6531122851427C77.69934510271483,382.1465492306984,151.96400966913612,415.9868341407373,220,402.91711652206016" />
                </svg>
                <svg className="circle circle2" viewBox="0 0 440 440" xmlns="http://www.w3.org/2000/svg">
                    <path fill='#292d32' d="M220,402.91711652206016C270.3223386966356,401.6894379997953,268.3008485716828,322.9022658231141,299.58406686966043,283.46617550759197C330.6537542862734,244.2992653973301,397.96400940832507,229.54707666193352,397.1815031525844,179.55947812278484C396.33027844930564,125.18204864928909,345.5183306745973,84.68202461490966,296.11633815387,61.94279519475375C247.81987526465218,39.712429701718285,193.689916888089,43.039492154391866,144.51865118671446,63.261364147072634C90.73855828184018,85.37863403964825,24.155924576694577,116.65778925360473,21.79108694612241,174.76010902948778C19.508742183065092,230.83563977528502,98.5869023497192,245.10456292524546,135.39300274298026,287.4718288257198C168.31217199065367,325.36487323512586,169.81976659746323,404.1413282019249,220,402.91711652206016" />
                </svg>
                <svg className="circle circle3" viewBox="0 0 440 440" xmlns="http://www.w3.org/2000/svg">
                    <path fill='#31363d' d="M220,307.0198666249566C258.9514450694069,306.4948210724719,307.78118048857,339.8021751105605,331.06745308659765,308.5733382075064C354.4174101698707,277.2590950894984,303.36043031597126,240.87086691573532,298.196202012765,202.1522271684925C291.54034989754024,152.25018466965176,337.89780975599047,88.94372248000998,297.72636125100945,58.5995477845679C258.031167975088,28.61512043227701,198.1840258802635,65.20264739934713,161.78230544025988,99.10971158758662C134.83198932374654,124.21308822501983,152.74601696506264,166.8966198472641,143.2561514960392,202.48371737938467C132.80954799254886,241.65859282641875,81.1200765304895,278.9368641230153,105.11330177373583,311.61908457200894C128.97575203561794,344.1231707048852,179.68081380847886,307.56334862804886,220,307.0198666249566" />
                </svg>
                <svg className="circle circle4" viewBox="0 0 440 440" xmlns="http://www.w3.org/2000/svg">
                    <path fill='#292d32' d="M220,334.99252739641815C273.88302517870636,333.3308675705655,332.0296001464815,376.8403224533007,373.5373587799361,342.4419578262892C419.5586227396929,304.30315509634033,430.3411406590333,233.01488336702508,411.50169780001266,176.2909871425126C394.17279411953854,124.1151907874133,338.1799662803745,100.85171730219439,286.80096882336403,81.28635892652302C242.34485265471886,64.35726556385485,191.68090816045975,52.15912365526377,151.21862122647875,77.17399529103672C113.8066414029656,100.30308521252996,119.55548889344298,152.25641626129038,104.91564530058102,193.73274703545619C85.48046430419845,248.79481040714276,17.229252880862262,307.0229263090268,54.11040733513293,352.2925356427731C91.05554286765445,397.6406782670312,161.5350838248325,336.79548486044166,220,334.99252739641815" />
                </svg>
            </div>
            <header className="App-header">
                <div className='content-img-text'>
                    <h1>Optimiza tu empresa mediante el desarrollo personalizado de software</h1>
                    <h2>En Scalar, nos dedicamos al desarrollo de software 100% seguro y escalable. Reserva tu asesoría gratuita y descubre cómo la automatización puede potenciar tu empresa.</h2>
                    <button className="btn btn-warning">Consulta con nosotros</button>
                </div>
                <div className='content-img-banner'>
                    <img className="img-fluid img-banner3" src={ImgBanner3} alt="ImgBanner" />
                    <img className="img-fluid img-banner sticky-style" src={ImgBanner1} alt="ImgBanner" />
                    <img className="img-fluid img-banner2" src={ImgBanner2} alt="ImgBanner"/>
                </div>     
            </header>
        </>
    )
}