import Coding from './assets/img/Coding_Two_Color.png';
import Ghetex from './assets/img/portafolio/gethex-peru-nuevo.png';
export default function Portafolio(){
    return(<>
        <main className="mt-5 pt-5 bg-scalar-base text-light">
            <div className="container marketing">
            <img src={Coding} alt="img" />
            <div className="row row-cols-1 row-cols-md-3 g-4 pb-5 justify-content-center">
                <div className="col-lg-12">
                    <h1>Clientes</h1>
                    <h4>¿Quiénes confiaron en Nosotros?</h4>
                </div>
                <div className="col-lg-2">
                    <div className="card bg-card-scalar">
                        <a href="https://www.gethexperu.com" className="btn" target="_blank" rel="noreferrer">
                            <img src={Ghetex} className="card-img-top" alt="img" />
                        </a>
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="card bg-card-scalar">
                        <a href="https://paket.pe" className="btn" target="_blank" rel="noreferrer">
                            {/* <img src={Ghetex} className="card-img-top" alt="img" /> */}
                            <h3 className='text-white paket'>PAKET</h3>
                        </a>
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="card bg-card-scalar">
                        <a href="https://www.infocsantivanez.net/Account/login" className="btn" target="_blank" rel="noreferrer">
                           <h3 className='text-white casa-santivanez'>Casa Santivañez </h3> 
                        </a>
                    </div>
                </div>
            </div>
            </div>
        </main>
    </>)
}