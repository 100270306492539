import LogoScalar from '../assets/img/logo-scalar2.png'
import './Footer.css';
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
export default function Footer(){
    return(
        <>
            <footer className="footer mt-auto py-3 bg-light">
                <div className="container">
                    <div className="row">
                        <img alt="logo-footer" className="img-logo-footer pb-3" src={LogoScalar}/>
                        <span className="text-muted">© todos los derechos reservados.</span>
                    </div>
                </div>
            </footer>
            <div className="ico-whatsapp-content">
                <a href="https://api.whatsapp.com/send?phone=51946564003&amp;text=Me%20gustar%C3%ADa%20adquirir%20una%20p%C3%A1gina%20web&amp;source=&amp;data=" >
                    <FontAwesomeIcon icon={faWhatsapp} />
                </a>
            </div>
        </>
    )
}