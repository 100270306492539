import LogoScalar from '../assets/img/logo-scalar.png';
import './Menu.css';
import { Link} from 'react-router-dom';
export default function Menu(){

    return (
        <>
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark bg-dark-scalar">
        <div className="container">
            <Link className="navbar-brand"to="/">
            <img className="img-logo" src={LogoScalar} alt="logo" />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                <li className="nav-item">
                    {/* <a className="nav-link active" aria-current="page" href="#">Inicio</a> */}
                    <Link className="nav-link"to="/inicio">Inicio</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link"to="/nosotros">Nosotros</Link>
                    {/* <a className="nav-link" href="#">Acerca de</a> */}
                </li>
                <li className="nav-item">
                    {/* <a className="nav-link" href="#">Contacto</a> */}
                    <Link className="nav-link"to="/portafolio">Portafolio</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link"to="/contacto">Contacto</Link>
                </li>
            </ul>
            {/* <form className="d-flex">
                <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                <button className="btn btn-outline-success" type="submit">Search</button>
            </form> */}
            </div>
        </div>
        </nav>
        </>
    )
}