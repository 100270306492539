import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';



const Root = () => {
    const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
    const [showInstallPrompt, setShowInstallPrompt] = useState(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event: any) => {
            event.preventDefault();
            setDeferredPrompt(event);
            setShowInstallPrompt(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('El usuario acept� la instalaci�n');
                } else {
                    console.log('El usuario rechaz� la instalaci�n');
                }
                setDeferredPrompt(null);
                setShowInstallPrompt(false);
            });
        }
    };

    const handleCancel = () => {
        setShowInstallPrompt(false);
    };

    return (
        <>
            <App />
            {showInstallPrompt && (
                <div
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        left: '20px',
                        backgroundColor: '#192025',
                        padding: '10px',
                        borderRadius: '5px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                        zIndex: 1000,
                        color:'white'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            style={{
                                width: '100px'
                            }}
                            src="logo192.png"
                            alt="Logo"
                        />
                    </div>
                    
                    <p>&iexcl;Agrega esta aplicaci&oacute;n a tu pantalla de inicio!</p>
                    <div

                        style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                        }}

                    >
                        <button
                            onClick={handleInstallClick}
                            style={{
                                backgroundColor: '#272e41',
                                color: 'white',
                                padding: '8px 16px',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                        >
                            Instalar
                        </button>
                        <button
                            onClick={handleCancel}
                            style={{
                                backgroundColor: '#f44336',
                                color: 'white',
                                padding: '8px 16px',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                        >
                            Cancelar
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Root />);

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/service-worker.js', { type: 'module' })
            .then((registration) => {
                console.log('Service Worker registrado:', registration);
            })
            .catch((error) => {
                console.log('Error al registrar el Service Worker:', error);
            });
    });
}


reportWebVitals();
