import Header from './utils/Header';
import Carousel from './utils/Carousel';
// import Graphic from './assets/img/graphic.png';
// import Authentication from './assets/img/authentication.png';
// import OnlineStore from './assets/img/online_store.png';

import backend from './assets/img/data_process_monochromatic.png';
import frontend from './assets/img/online_store.png';
import movil from './assets/img/user_group_two_color.png';
import cloud from './assets/img/data_center_monochromatic.png';
import { useEffect } from 'react';

export default function Home() {

    useEffect(() => {
        console.log("Hola desde Home");
    });

    return(
        
        <>
        <Header></Header>
        <main className="pt-4 bg-scalar-base text-light">
            <div className="container marketing">
                <h2 className="pt-4 title-scalar">Desarrollo de software a medida</h2>
                <div className="row justify-content-md-center">
                    <p className="col-lg-6 presentation">
                    Diseñamos soluciones de software personalizadas y multiplataforma para satisfacer las necesidades específicas de tu empresa. Ya sea la implementación de un CRM, una plataforma de aula virtual, un sistema de comercio electrónico o una aplicación móvil integrada a tu negocio, contamos con la capacidad para ofrecer soluciones adaptadas a tus metas.<br></br><br></br>
                    Nuestro equipo de desarrollo se distingue por trabajar con tecnologías destacadas, robustas, escalables y compatibles con proveedores de servicios en la nube como Azure y AWS. Sobresalimos en la creación de software personalizado con arquitecturas limpias y sólidas, garantizando un rendimiento óptimo y una fácil expansión en el futuro.<br></br>
                    Confía en nosotros para llevar a cabo el desarrollo de software que potencie tu empresa, optimice tus procesos y brinde una experiencia excepcional tanto para tus clientes como para tus usuarios internos.
                    </p>
                </div>
                <h4 className="pt-4">Tecnología con las que trabajamos</h4>
            <Carousel></Carousel>
            {/* <div className="row">
            <div className="col-lg-4">
                <svg className="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#777"/><text x="50%" y="50%" fill="#777" dy=".3em">140x140</text></svg>

                <h2>Heading</h2>
                <p>Some representative placeholder content for the three columns of text below the carousel. This is the first column.</p>
                <p><a className="btn btn-secondary" href="#">View details &raquo;</a></p>
            </div>
            <div className="col-lg-4">
                <svg className="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#777"/><text x="50%" y="50%" fill="#777" dy=".3em">140x140</text></svg>

                <h2>Heading</h2>
                <p>Another exciting bit of representative placeholder content. This time, we've moved on to the second column.</p>
                <p><a className="btn btn-secondary" href="#">View details &raquo;</a></p>
            </div>
            <div className="col-lg-4">
                <svg className="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#777"/><text x="50%" y="50%" fill="#777" dy=".3em">140x140</text></svg>

                <h2>Heading</h2>
                <p>And lastly this, the third column of representative placeholder content.</p>
                <p><a className="btn btn-secondary" href="#">View details &raquo;</a></p>
            </div>
            </div> */}


            <hr className="featurette-divider" />

            <div className="row">
                <div className="col">
                    <h4 className="pt-4 title-scalar">Soluciones a medida</h4>
                </div>
            </div>

            <div className="row">
                <div className="col-md-5">
                    <img src={backend} className="img-width-home" alt="img" />
                </div>
                <div className="col-md-7 align-self-center">
                    <h4 className="featurette-heading title-scalar">Desarrollo <span className="text-light">BackEnd</span></h4>
                    <p className="lead  text-pd presentation">
                    Este servicio se especializa en la implementación de la infraestructura que permite que las aplicaciones funcionen de manera eficiente, segura y escalable. En resumen, el servicio de Desarrollo Backend es fundamental para construir la columna vertebral robusta de una aplicación, asegurando su funcionalidad, seguridad y capacidad de escalamiento a medida que la aplicación crece y evoluciona.
                    </p>
                    {/* <a href="https://api.whatsapp.com/send?phone=51946564003&text=quiero%20una%20cotizaci%C3%B3n%20acerca%20de%20la%20Plataforma%20Administrativa%20tipo%20CRM%20(Customer%20Relationship%20Management)" className="btn btn-warning" target="_blank" rel="noopener noreferrer">Cotizar</a> */}
                </div>   
            </div>

            <hr className="featurette-divider" />

            <div className="row featurette">
                <div className="col-md-7 align-self-center">
                    <h4 className="featurette-heading title-scalar">Desarrollo <span className="text-light">FrontEnd</span></h4>
                    <p className="lead text-pd presentation">
                    Este servicio se centra en la implementación de la parte visual y funcional que los usuarios finales interactúan directamente en un sitio web o aplicación. 
                    </p>
                    {/* <a href="https://api.whatsapp.com/send?phone=51946564003&text=quiero%20una%20cotizaci%C3%B3n%20acerca%20de%20Plataforma%20de%20Comercio%20Electronico%20(Ecommerce)" className="btn btn-warning" target="_blank" rel="noopener noreferrer">Ver demo</a> */}
                </div>
                <div className="col-md-5">
                    <img src={frontend} className="img-width-home" alt="img" />
                </div>
            </div>

            <hr className="featurette-divider" />

            <div className="row">
                <div className="col-md-5">
                    <img src={movil} className="img-width-home" alt="img" />
                </div>
                <div className="col-md-7 align-self-center">
                    <h4 className="featurette-heading title-scalar">Desarrollo <span className="text-light">Móvil</span></h4>
                    <p className="lead  text-pd presentation">
                    Este servicio se centra en la implementación de software que aprovecha las características y capacidades únicas de los sistemas operativos móviles, como iOS (para dispositivos Apple) y Android.
                    </p>
                    {/* <a href="https://api.whatsapp.com/send?phone=51946564003&text=quiero%20una%20cotizaci%C3%B3n%20acerca%20de%20Plataforma%20de%20tipo%20Aula%20Virtual" className="btn btn-warning" target="_blank" rel="noopener noreferrer">Ver demo</a> */}
                </div>
            </div>
            <div className="row featurette">     
                <div className="col-md-7 align-self-center">
                    <h4 className="featurette-heading title-scalar">Desarrollo <span className="text-light">nivel Cloud</span></h4>
                    <p className="lead  text-pd presentation">
                    Los servicios de desarrollo en la nube permiten a las organizaciones construir y ejecutar aplicaciones sin la necesidad de invertir en infraestructuras físicas costosas.
                    </p>
                    {/* <a href="https://api.whatsapp.com/send?phone=51946564003&text=quiero%20una%20cotizaci%C3%B3n%20acerca%20de%20Plataforma%20de%20tipo%20Aula%20Virtual" className="btn btn-warning" target="_blank" rel="noopener noreferrer">Ver demo</a> */}
                </div>
                <div className="col-md-5">
                    <img src={cloud} className="img-width-home" alt="img" />
                </div>
            </div>

            {/* <hr className="featurette-divider" /> */}
            <br></br>
            </div>
            
        </main>
    </>
    )
}